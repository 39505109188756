import { useCallback, useEffect, useReducer } from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import unionWith from 'lodash/unionWith';
import {
  setQuery,
  setIsFetching,
  setError,
  updateSuggestions,
  resetSuggestions,
  resetSearch,
} from './RouteSearchAction';
import { reducer, initialState } from './RouteSearchReducer';
import useLocalRoute from '../LocalRouteHook';
import { useFetchTimetableRoutes } from '../AtApiHook';
import { DEBOUNCE_TIME } from '../../utils/Config';

const useRouteSearch = (storageKey, routeType) => {
  const [routeSuggestions, dispatch] = useReducer(reducer, initialState);
  const { filteredRoutes: localRoutes, filterRoutes: filterLocalRoutes, addRoute } = useLocalRoute(storageKey);
  const { removeRecentSearches } = useLocalRoute('recentTimetableRoutes');
  const [remoteRoutes, getTimetableRoutes] = useFetchTimetableRoutes();

  useEffect(() => {
    const localSuggestions = !isEmpty(routeSuggestions.query) ? [{ results: localRoutes, title: 'Recent searches / Rapunga tata' }] : localRoutes;

    dispatch(updateSuggestions(localSuggestions));

    if (!isEmpty(routeSuggestions.query) && !isEmpty(remoteRoutes.results)) {
      const mergedSuggestions = unionWith(localRoutes, remoteRoutes.results, (a, b) => a.route_short_name === b.route_short_name && a.last_stop_headsign === b.last_stop_headsign);

      dispatch(updateSuggestions([{ results: mergedSuggestions }]));
    }
  }, [localRoutes, remoteRoutes]);

  useEffect(() => {
    dispatch(setIsFetching(remoteRoutes.isFetching));
  }, [remoteRoutes]);

  useEffect(() => {
    removeRecentSearches();
  }, []);

  const debouncedFetchRemoteRoutes = useCallback(debounce((query, routeTypes) => {
    try {
      getTimetableRoutes(query, routeTypes);
    } catch (err) {
      dispatch(setError(err));
    }
  }, DEBOUNCE_TIME), []);

  const fetchSuggestions = (query) => {
    if (routeSuggestions.query !== query) {
      dispatch(setQuery(query));
      dispatch(resetSuggestions());

      if (!isEmpty(query)) {
        debouncedFetchRemoteRoutes(query, routeType);
      } else {
        debouncedFetchRemoteRoutes.cancel();
      }
    }

    filterLocalRoutes(query, routeType);
  };

  const clearSuggestions = () => {
    dispatch(resetSuggestions());
  };

  return {
    routeType: routeSuggestions.routeType,
    query: routeSuggestions.query,
    suggestions: routeSuggestions.suggestions,
    fetching: routeSuggestions.isFetching,
    fetchSuggestions,
    clearSuggestions,
    saveRouteToLocal: addRoute,
    resetSearch,
  };
};

export default useRouteSearch;
