import React, { useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import RouteSearch from '../RouteSearch';
import Label from '../ATSearch/Label';
import { getTimetablesUrlParams } from '../../utils/UrlGenerator';
import * as Icons from '../Icons';
import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab';

const transportModes = [
  {
    id: 'bus',
    title: 'Bus',
    icon: Icons.Bus,
    text: 'Bus or route number',
    placeholder: 'e.g. 161 or New Lynn',
    mode: '3',
  },
  {
    id: 'train',
    title: 'Train',
    icon: Icons.Train,
    text: 'Train line or destination',
    placeholder: 'e.g. South or Britomart',
    mode: '2',
  },
  {
    id: 'ferry',
    title: 'Ferry',
    icon: Icons.Ferry,
    text: 'Ferry route or destination',
    placeholder: 'e.g. Half moon bay',
    mode: '4',
  },
];

export default function Timetables({ ttUrl }) {
  const [currentTab, setCurrentTab] = useState(transportModes[0]);

  const gotoTimetablesPage = (route) => {
    if (route) {
      window.location.href = `${ttUrl}?${getTimetablesUrlParams(route, currentTab.id.toUpperCase())}`;
    }
  };

  const SearchInput = (props) => {
    /*
     * TODO: remove the text state since parent didn't use it to do anything, this requires RouteSearch
     * to use the Search component in a non-controlled mode.
     */
    const [text, setText] = useState('');
    return (
      <Label htmlFor="tt-route-search-input" className="route-input-label" text={props.text} >
        <RouteSearch
          id="tt-route-search-input"
          storageKey="TimetableRoutes-recent"
          routeType={props.mode}
          placeholder={props.placeholder}
          onRouteDetermined={gotoTimetablesPage}
          onTextChange={setText}
          value={text}
          className="tt-route-search"
        />
      </Label>
    );
  };
  // As long as mode don't change the Content shouldn't rerender(input text shouldn't update the Timetable overall)
  const Content = React.memo(SearchInput, (prevProps, nextProps) => prevProps.mode === nextProps.mode);

  const switchTab = (tab) => {
    setCurrentTab(find(transportModes, ['id', tab]));
  };

  return (
    <>
      <Label htmlFor="autoSuggestInput" className="route-input-label" text="Transport mode">
        <Tabs className="transport-mode-tabs" activeTab={currentTab.id} onChange={switchTab} >
          {
            transportModes.map((tab) => {
              const Icon = tab.icon;
              return (
                <Tab
                  id={tab.id}
                  key={tab.id}
                  className="transport-mode-tab"
                  activeClassName="transport-mode-tab--active"
                >
                  <div className="transport-mode-tab__icon-wrapper">
                    <div>
                      <Icons.Checked className="transport-mode-tab__icon-checked" focusable="false" />
                      <Icon className="transport-mode-tab__icon" focusable="false" />
                    </div>
                  </div>
                  <div className="transport-mode-tab__title">{tab.title}</div>
                </Tab>
              );
            })
          }
        </Tabs>
      </Label>
      <Content {...currentTab} />
    </>
  );
}

Timetables.defaultProps = {
  placeholder: '',
};

Timetables.propTypes = {
  ttUrl: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};
