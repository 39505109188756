import React from 'react';
import PropTypes from 'prop-types';
import { getSearchIconName } from '../../utils/AddressSearchHelper';
import * as Icons from '../Icons';

export default function AddressIcon({ addr }) {
  const iconName = getSearchIconName(addr);
  if (!iconName) return null;
  const SearchIcon = Icons[iconName];
  return <SearchIcon className="suggestion-item__icon" />;
}

AddressIcon.propTypes = {
  addr: PropTypes.object.isRequired,
};
