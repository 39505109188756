import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddressSearch from '../AddressSearch/AddressSearch';
import { logEventAddressSelectedInLiveDeparture } from '../../utils/GALogger';
import { getLiveDeparturesUrlParams } from '../../utils/UrlGenerator';
import Label from '../ATSearch/Label';

export default function LiveDepartures({ ldUrl }) {
  const [text, setText] = useState('');

  const gotoLiveDeparturePage = (address) => {
    if (address) {
      window.location.href = `${ldUrl}?${getLiveDeparturesUrlParams(address)}`;
      logEventAddressSelectedInLiveDeparture(address);
    }
  };

  return (
    <Label htmlFor="ld-address-input-autosuggest" text="Search for a stop">
      <AddressSearch
        id="ld-address-input-autosuggest"
        storageKey="liveDepartures"
        hasFindOnMap
        placeholder="Location or stop number"
        onAddressDetermined={gotoLiveDeparturePage}
        onTextChange={setText}
        value={text}
      />
    </Label>
  );
}

LiveDepartures.propTypes = {
  ldUrl: PropTypes.string.isRequired,
};
