import { useCallback, useEffect, useReducer } from 'react';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import {
  setQuery,
  setError,
  setIsFetching,
  updateSuggestions,
  resetSuggestions,
} from './AddressSuggestionsAction';
import { reducer, initialState } from './AddressSuggestionsReducer';
import useLocalAddress from '../LocalAddressHook';
import { useFetchForwardGeoCode } from '../AtApiHook';
import { createSelectionsFromAddresses, combineSearchResults } from '../../utils/AddressSearchHelper';
import { DEBOUNCE_TIME, GEOCODER_MIN_QUERY_LENGTH } from '../../utils/Config';

const useAddressSuggestions = (storageKey, hasCurrentLocation, hasFindOnMap) => {
  const [addressSuggestions, dispatch] = useReducer(reducer, initialState);
  const { filteredAddresses, filterAddresses } = useLocalAddress(storageKey);
  const [forwardGeoCode, getForwardGeoCode] = useFetchForwardGeoCode();

  useEffect(() => {
    const localSuggestions = createSelectionsFromAddresses(filteredAddresses, addressSuggestions.query, { hasCurrentLocation, hasFindOnMap });
    dispatch(updateSuggestions(localSuggestions));

    if (!(isEmpty(addressSuggestions.query) || isEmpty(forwardGeoCode.results))) {
      const mergedResults = combineSearchResults(filteredAddresses, forwardGeoCode.results);
      dispatch(updateSuggestions([{ results: mergedResults }]));
    }
  }, [filteredAddresses, forwardGeoCode]);

  useEffect(() => {
    dispatch(setIsFetching(forwardGeoCode.isFetching));
  }, [forwardGeoCode]);

  const debouncedCombineApiResult = useCallback(debounce((query) => {
    try {
      getForwardGeoCode(query);
    } catch (err) {
      dispatch(setError(err));
    }
  }, DEBOUNCE_TIME), []);

  const fetchSuggestions = (query) => {
    const trimmedQuery = query?.trim() ?? '';
    if (addressSuggestions.query !== trimmedQuery) {
      dispatch(setQuery(trimmedQuery));
      dispatch(resetSuggestions());

      if (trimmedQuery?.length >= GEOCODER_MIN_QUERY_LENGTH) {
        debouncedCombineApiResult(trimmedQuery);
      } else {
        debouncedCombineApiResult.cancel();
      }
    }

    filterAddresses(trimmedQuery);
  };

  const clearSuggestions = () => {
    dispatch(resetSuggestions());
  };

  return {
    suggestions: addressSuggestions.suggestions,
    fetching: addressSuggestions.isFetching,
    fetchSuggestions,
    clearSuggestions,
  };
};

export default useAddressSuggestions;
