import {
  UPDATE_FETCHING,
  SET_ERROR,
  SET_QUERY,
  UPDATE_SUGGESTIONS,
} from './AddressSuggestionsAction';

// initial state
export const initialState = {
  isFetching: false,
  query: '',
  suggestions: [],
  error: null,
};

// reducer
export function reducer(state, action) {
  switch (action.type) {
    case UPDATE_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
        selection: null,
        query: '',
      };
    case SET_QUERY:
      return {
        ...state,
        query: action.query,
      };
    case UPDATE_SUGGESTIONS:
      return {
        ...state,
        suggestions: action.suggestions,
      };
    default:
      throw new Error();
  }
}
