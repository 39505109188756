import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  JourneyPlanner,
  LiveDepartures,
  Timetables,
  Tabs,
  Tab,
  Icons,
} from './components';
import { DEFAULT_JP_URL, DEFAULT_LD_URL, DEFAULT_TT_URL } from './utils/Config';
import { getJourneyPlannerUrl, getLiveDeparturesUrl, getTimetablesUrl } from './utils/UrlGenerator';

function App({ jpUrl, ldUrl, ttUrl }) {
  const [selectedTab, setSelectedTab] = useState('journeyPlanner');

  const renderContent = () => {
    switch (selectedTab) {
      case 'journeyPlanner':
        return <JourneyPlanner jpUrl={getJourneyPlannerUrl(jpUrl)} />;
      case 'liveDepartures':
        return <LiveDepartures ldUrl={getLiveDeparturesUrl(ldUrl)} />;
      case 'timetables':
        return <Timetables ttUrl={getTimetablesUrl(ttUrl)} />;
      default:
        return null;
    }
  };

  return (
    <div className="main">
      <Tabs
        className="btf-tabs"
        activeTab={selectedTab}
        onChange={setSelectedTab}
      >
        <Tab
          id="journeyPlanner"
          key="journeyPlanner"
          className="btf-tab"
          activeClassName="btf-tab--active"
        >
          <Icons.JourneyPlanner className="btf-tab__icon" focusable="false"/>
          <span className="btf-tab__title">Journey planner</span>
        </Tab>
        <Tab
          id="liveDepartures"
          key="liveDepartures"
          className="btf-tab"
          activeClassName="btf-tab--active"
        >
          <Icons.LiveDepartures className="btf-tab__icon" focusable="false"/>
          <span className="btf-tab__title">Live departures</span>
        </Tab>
        <Tab
          id="timetables"
          key="timetables"
          className="btf-tab"
          activeClassName="btf-tab--active"
        >
          <Icons.Timetable className="btf-tab__icon" focusable="false"/>
          <span className="btf-tab__title">Timetables</span>
        </Tab>
      </Tabs>
      <div className="content">
        <div className="at-flex-grid">
          <div className="row">
            <div className={classnames('col-xs-12', 'form')}>
              { renderContent() }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

App.propTypes = {
  jpUrl: PropTypes.string,
  ldUrl: PropTypes.string,
  ttUrl: PropTypes.string,
};

App.defaultProps = {
  jpUrl: DEFAULT_JP_URL,
  ldUrl: DEFAULT_LD_URL,
  ttUrl: DEFAULT_TT_URL,
};

export default App;
