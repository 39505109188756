import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import useAddressSelection from '../AddressSelectionHook';
import useAddressSuggestions from '../AddressSuggestionsHook';
import { getAddressLabel } from '../../utils/AddressSearchHelper';

const useAddressSearch = (storageKey, hasCurrentLocation, hasFindOnMap) => {
  const [fetching, setFetching] = useState(false);
  const {
    selection, fetching: fetchingSelection, selectAddress, error, clearError,
  } = useAddressSelection(storageKey);
  const {
    suggestions,
    fetching: fetchingSuggestions,
    fetchSuggestions,
    clearSuggestions,
  } = useAddressSuggestions(storageKey, hasCurrentLocation, hasFindOnMap);

  useEffect(() => {
    setFetching(fetchingSelection || fetchingSuggestions);
  }, [fetchingSuggestions, fetchingSelection]);

  const fetchAddressSuggestions = (query) => {
    if (isEmpty(selection) || !isEqual(query, getAddressLabel(selection))) {
      fetchSuggestions(query);
    }
  };

  return {
    error,
    selection,
    selectAddress,
    suggestions,
    fetchSuggestions: fetchAddressSuggestions,
    clearSuggestions,
    clearError,
    fetching,
  };
};

export default useAddressSearch;
