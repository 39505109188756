import React from 'react';
import PropTypes from 'prop-types';

export default function SectionTitle({ title }) {
  return title ? <span>{ title }</span> : null;
}

SectionTitle.defaultProps = {
  title: '',
};

SectionTitle.propTypes = {
  title: PropTypes.string,
};
