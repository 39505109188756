import { GeocodingClient } from '@at.govt.nz/geocoder-sdk';
import ADDRESS_CATEGORIES from '../constants/addressCategories';
import {
  MAPBOX_TOKEN,
  AT_API_URL,
  AT_API_KEY,
  GEOCODER_MIN_QUERY_LENGTH,
  CDN_BASE_URL,
} from '../utils/Config';
import { recordException } from '../utils/GALogger';
import GenerateDisplayText from '../utils/GenerateDisplayText';
import GenerateAddress from '../utils/GenerateAddress';

let mapboxGeocodingClient;

const updateMapCategory = (oldCategory) => {
  const key = oldCategory?.toUpperCase();

  if (key === 'BUS_STOP') {
    return ADDRESS_CATEGORIES.BUSSTOP;
  }

  return oldCategory;
};

const processLocation = ({
  geometry: { coordinates },
  properties: {
    category, name, type, stopCode, address,
  },
}) => ({
  name,
  displayText: GenerateDisplayText(name, type, stopCode, address),
  address: GenerateAddress(name, type, stopCode, address),
  category: updateMapCategory(category),
  lat: coordinates[1],
  lng: coordinates[0],
  type,
});

const forwardGeocode = async (query, currentPosition) => {
  const trimmedQuery = query?.trim() ?? '';
  if (trimmedQuery.length < GEOCODER_MIN_QUERY_LENGTH) {
    return [];
  }

  try {
    const proximity = currentPosition ? [currentPosition.longitude, currentPosition.latitude] : undefined;
    const geocodeResult = await mapboxGeocodingClient.forwardGeocode({ query: trimmedQuery, proximity });

    if (geocodeResult.errors?.length) {
      const description = `Geocoder forwardGeocode partial errors: ${geocodeResult.errors.join('; ')}`;
      recordException({ description });
    }

    const locations = geocodeResult?.features || [];

    return locations.map(processLocation);
  } catch (error) {
    recordException({ description: `Geocoder forwardGeocode error: ${error.message}` });
    return [];
  }
};

const reverseGeocode = async (latitude, longitude) => {
  try {
    const geocodeResult = await mapboxGeocodingClient.reverseGeocode([longitude, latitude]);

    if (geocodeResult?.errors?.length) {
      throw new Error(`Reverse geocode error: ${geocodeResult.errors.join('; ')}`);
    }

    if (geocodeResult?.features?.length > 0) {
      return processLocation(geocodeResult.features[0]);
    }

    return undefined;
  } catch (error) {
    recordException({ description: `Geocoder reverseGeocode error: ${error.message}` });
    throw error;
  }
};

const getInstance = () => {
  if (!mapboxGeocodingClient) {
    const FORWARD_GEOCODE_LIMIT_GTFS = { station: 10, others: 5, total: 10 }; // limit of GTFS results
    const FORWARD_GEOCODE_LIMIT_MAPBOX = 10; // limit of Mapbox results

    mapboxGeocodingClient = new GeocodingClient({
      cdnBaseUrl: CDN_BASE_URL,
      gtfs: {
        apiBaseUrl: AT_API_URL,
        apiKey: AT_API_KEY,
      },
      limit: {
        gtfs: FORWARD_GEOCODE_LIMIT_GTFS,
        mapbox: FORWARD_GEOCODE_LIMIT_MAPBOX,
      },
      mapbox: {
        accessToken: MAPBOX_TOKEN,
      },
    });
  }

  return {
    forwardGeocode,
    reverseGeocode,
  };
};

export default {
  GEOCODER_MIN_QUERY_LENGTH,
  getInstance,
};
