import { useState } from 'react';
import GeocoderService from '../../services/GeocoderService';

const useFetchReverseGeoCode = () => {
  const [reverseGeoCode, setReverseGeoCode] = useState(null);

  const fetchReverseGeoCode = async (lat, lng) => {
    try {
      const location = await GeocoderService.getInstance().reverseGeocode(lat, lng);
      if (location) {
        setReverseGeoCode(location);
      } else {
        setReverseGeoCode(null);
        throw new Error(`No address can be found for ${lat},${lng}`);
      }
    } catch (error) {
      setReverseGeoCode(null);
      throw error;
    }
  };

  return [reverseGeoCode, fetchReverseGeoCode];
};

export default useFetchReverseGeoCode;
