import JoinStrings from './JoinStrings';

const GenerateDisplayText = (name, type, stopCode, address) => {
  switch (type) {
    case 'GTFS':
      return `${stopCode ?? ''} - ${name}`;
    case 'POI':
      return JoinStrings([name, address?.street, address?.locality, address?.place, address?.postcode]);
    case 'ADDRESS':
      return JoinStrings([name, address?.locality, address?.place, address?.postcode]);
    default:
      return '';
  }
};

export default GenerateDisplayText;
