import startsWith from 'lodash/startsWith';
import queryString from 'query-string';
import { AT_WEBSITE_URL } from './Config';
import { getRouteLabel } from './RouteSearchHelper';

const getJourneyPlannerUrlParams = (
  origin,
  destination,
) => {
  if (!origin || !destination) {
    throw new Error('Starting point and destination are required');
  }

  const queryObject = {
    fromAddress: origin.displayText ?? origin.address,
    fromCoordinates: `${origin.lat},${origin.lng}`,
    toAddress: destination.displayText ?? destination.address,
    toCoordinates: `${destination.lat},${destination.lng}`,
  };

  return queryString.stringify(queryObject);
};

const getLiveDeparturesUrlParams = (address) => queryString.stringify(address);
const getTimetablesUrlParams = (route, routeType) => queryString.stringify({
  name: getRouteLabel(route),
  route_type: routeType,
});

const isAbsoluteUrl = (url) => startsWith(url, 'http://') || startsWith(url, 'https://');

const getJourneyPlannerUrl = (jpUrl) => (isAbsoluteUrl(jpUrl) ? jpUrl : new URL(jpUrl, AT_WEBSITE_URL));
const getLiveDeparturesUrl = (ldUrl) => (isAbsoluteUrl(ldUrl) ? ldUrl : new URL(ldUrl, AT_WEBSITE_URL));
const getTimetablesUrl = (ttUrl) => (isAbsoluteUrl(ttUrl) ? ttUrl : new URL(ttUrl, AT_WEBSITE_URL));

export {
  getJourneyPlannerUrl,
  getLiveDeparturesUrl,
  getTimetablesUrl,
  getJourneyPlannerUrlParams,
  getLiveDeparturesUrlParams,
  getTimetablesUrlParams,
};
