// actions
export const UPDATE_SELECTION = 'UPDATE_SELECTION';
export const SET_ERROR = 'SET_ERROR';
export const SET_IS_CURRENT_LOCATION = 'SET_IS_CURRENT_LOCATION';

export const updateSelection = (addr) => ({
  type: UPDATE_SELECTION,
  selection: addr,
});

export const setIsCurrentLocation = (hasCurrentLocation) => ({
  type: SET_IS_CURRENT_LOCATION,
  hasCurrentLocation,
});

export const setError = (error) => ({
  type: SET_ERROR,
  error,
});
