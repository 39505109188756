import ReactGA from 'react-ga';
import isEmpty from 'lodash/isEmpty';

const GA_CODE = process.env.REACT_APP_GA_CODE;
const TRACKER_NAME = 'AT_BTF_MINI_WIDGET';
// tracker originally used by the JourneyPlanner app
const JP_GA_CODE = process.env.REACT_APP_JP_GA_CODE;
const JP_TRACKER_NAME = 'jp';

export const CATEGORIES = {
  LIVE_DEPARTURES: 'Live departures',
  JOURNEY_PLANNER: 'Journey planner',
  TIMETABLES: 'timetables',
  APP_PROMO_BANNER: 'App banner',
};

const ADDRESS_CATEGORY_ACTION_MAP = {
  ADDRESS: 'Select address',
  BUSSTOP: 'Select stop',
  GPS: 'Select current location',
  MAPSTOP: 'Select find stop on map',
  POI: 'Select point of interest',
  SAVED: 'Select saved address',
};

export const APP_PROMO_BANNER_ACTION = {
  CLOSE: 'close app promo banner',
  VIEW: 'view app promo banner',
};

export const TIMETABLES_ACTION = {
  ROUTE_SELECT: 'Route variant selected',
  STOP_SELECT: 'Stop selected',
  DATE_SELECT: 'Date search changed',
  TRANSPORT_MODE: 'Transport mode selected',
  LIVE_DEPARTURES: 'Live departures link selected',
  SCHOOL_BUS: 'School Bus schedule selected',
};

export const JOURNEY_PLANNER_CATEGORIES = {
  NAVIGATION: 'Navigation',
};

export const JOURNEY_PLANNER_ACTION = {
  SHOW_EXT_PROVIDERS: 'Show External Providers Routes',
  HIDE_EXT_PROVIDERS: 'Hide External Providers Routes',
};

export const JOURNEY_PLAN_TYPE = {
  ORIGIN: 'Origin',
  DEST: 'Destination',
};

function getAddressEventAction({ saved, category, currentLocation }) {
  if (saved) {
    return ADDRESS_CATEGORY_ACTION_MAP.SAVED;
  }
  if (currentLocation) {
    return ADDRESS_CATEGORY_ACTION_MAP.GPS;
  }
  if (isEmpty(category)) {
    return ADDRESS_CATEGORY_ACTION_MAP.ADDRESS;
  }
  if (ADDRESS_CATEGORY_ACTION_MAP[category]) {
    return ADDRESS_CATEGORY_ACTION_MAP[category];
  }
  return ADDRESS_CATEGORY_ACTION_MAP.POI;
}

export function init() {
  ReactGA.initialize([
    {
      trackingId: GA_CODE,
      gaOptions: {
        name: TRACKER_NAME,
      },
    },
    {
      trackingId: JP_GA_CODE,
      gaOptions: {
        name: JP_TRACKER_NAME,
      },
      alwaysSendToDefaultTracker: false,
    },
  ]);
}

/**
 * Send an event to the online analytics service for tracking purpose
 * For example if the user selected the address britomart train station
 * from address input box in Live departures screen.
 *
 * @param {*} category category of the event. Please choose from CATEGORIES.
 * @param {*} action action of the event. Please choose from ACTIONS
 * @param {*} label label of the event. Could be the target value of the event
 */
export function logEvent(category, action, label) {
  ReactGA.event({
    category,
    action,
    label,
  }, [TRACKER_NAME]);
}

export function recordException({ description }) {
  ReactGA.exception({
    description,
  }, [TRACKER_NAME]);
}

/**
 * Send an event to the online analytics service using the **JourneyPlanner tracker**
 *
 * @param {*} category category of the event. Please choose from JOURNEY_PLANNER_CATEGORIES.
 * @param {*} action action of the event. Please choose from JOURNEY_PLANNER_ACTION
 * @param {*} label label of the event. Could be the target value of the event
 */
export function logJourneyPlannerEvent(category, action, label) {
  ReactGA.event({
    category,
    action,
    label,
  }, [JP_TRACKER_NAME]);
}

export function logEventAddressSelectedInLiveDeparture({ saved, category, address }) {
  const eventCategory = CATEGORIES.LIVE_DEPARTURES;
  const action = getAddressEventAction({
    saved,
    category,
  });
  logEvent(eventCategory, action, address);
}

// Better way to log JP event is only when event is fired, but that event
// can be dropped on window href change. Thus we will log on address select.
export function logEventJourneyPlanAddressSelect(address, typeString) {
  logEvent(CATEGORIES.JOURNEY_PLANNER, getAddressEventAction(address), typeString);
}
