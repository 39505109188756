import { useState } from 'react';
import GeocoderService from '../../services/GeocoderService';
import { getCurrentPosition } from '../../utils/AddressSearchHelper';
import { recordException } from '../../utils/GALogger';

const useFetchForwardGeoCode = () => {
  const [forwardGeoCode, setForwardGeoCode] = useState({ results: [], isFetching: false });

  const fetchForwardGeoCode = async (query) => {
    setForwardGeoCode({ results: [], isFetching: true });

    // Feature detection for navigator.permissions - this has not been implemented in safari until version 16. In the
    // cases where the feature is not present or the feature does not properly return "granted", proximity geocoding
    // is disabled.
    let results;
    if ('permissions' in navigator
      && (await navigator.permissions.query({ name: 'geolocation' })).state === 'granted'
    ) {
      try {
        const currentPosition = await getCurrentPosition();
        results = await GeocoderService.getInstance().forwardGeocode(query, currentPosition?.coords);
      } catch (error) {
        recordException({ description: `Failed to get the user's location: ${error.code} - ${error.message}` });
        results = await GeocoderService.getInstance().forwardGeocode(query);
      }
    } else {
      results = await GeocoderService.getInstance().forwardGeocode(query);
    }

    setForwardGeoCode({ results, isFetching: false });
  };

  return [forwardGeoCode, fetchForwardGeoCode];
};

export default useFetchForwardGeoCode;
