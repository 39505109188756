import { useEffect, useReducer } from 'react';
import {
  updateSelection,
  setError,
  setIsCurrentLocation,
} from './AddressSelectionAction';
import { reducer, initialState } from './AddressSelectionReducer';
import useLocalAddress from '../LocalAddressHook';
import { useFetchReverseGeoCode } from '../AtApiHook';
import { isCurrentLocation, isFindOnMap, getCurrentPosition } from '../../utils/AddressSearchHelper';

const useAddressSelection = (storageKey) => {
  const [addressSelection, dispatch] = useReducer(reducer, initialState);
  const { addAddress: saveAddressToLocal } = useLocalAddress(storageKey);
  const [reverseGeoCode, fetchReverseGeoCode] = useFetchReverseGeoCode();

  // Handle user select an address
  useEffect(() => {
    if (addressSelection.hasCurrentLocation && reverseGeoCode) {
      saveAddressToLocal(reverseGeoCode);
      dispatch(updateSelection(reverseGeoCode));
    }
  }, [addressSelection.hasCurrentLocation, reverseGeoCode]);

  const selectAddress = async (addr) => {
    if (isCurrentLocation(addr)) {
      dispatch(setIsCurrentLocation(true));
      try {
        const position = await getCurrentPosition();
        const { latitude, longitude } = position.coords;
        await fetchReverseGeoCode(latitude, longitude);
      } catch (error) {
        dispatch(updateSelection(' '));
        dispatch(setError(error));
      }
    } else {
      dispatch(updateSelection(addr));
      if (!isFindOnMap(addr)) {
        saveAddressToLocal(addr);
      }
    }
  };

  const clearError = () => {
    dispatch(setError(null));
  };

  return {
    error: addressSelection.error,
    selection: addressSelection.selection,
    fetching: addressSelection.isFetching,
    selectAddress,
    clearError,
  };
};

export default useAddressSelection;
