import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Tab(props) {
  const {
    id,
    onClick,
    isActive,
    children,
    className,
    activeClassName,
  } = props;

  const nonInitialRender = useRef(false);
  const selectedTab = useRef(null);

  useEffect(() => {
    if (nonInitialRender.current) {
      if (isActive) {
        selectedTab.current.focus();
      }
    } else {
      nonInitialRender.current = true;
    }
  });

  return (
    <div
      id={id}
      role="tab"
      aria-selected={isActive}
      ref={selectedTab}
      tabIndex={isActive ? 0 : -1}
      onClick={() => onClick(id)}
      className={classNames('tab', className, {
        [`${activeClassName}`]: isActive,
      })}
    >
      {children}
    </div>
  );
}

Tab.propTypes = {
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
};

Tab.defaultProps = {
  isActive: false,
  onClick: () => { /* default empty function */ },
  className: 'tab',
  activeClassName: 'tab--active',
};

export default Tab;
