import React from 'react';
import PropTypes from 'prop-types';
import Button from '@at.govt.nz/buttons';
import Modal from 'react-modal';
import * as Icons from '../Icons';
import { Keyboard } from '../../utils/Keyboard';

const AppSelector = '#at-wayfinding-mini .main';
function getParent() {
  return document.querySelector(AppSelector);
}
export default function Confirm(props) {
  const {
    children,
    className,
    disabled,
    isOpen,
    maskClassName,
    onCancel,
    onConfirm,
    title,
    showCancelButton,
    showCloseButton,
    cancelLabel,
    confirmLabel,
  } = props;

  const onCloseButtonKeyDown = (event) => {
    switch (event.keyCode) {
      case Keyboard.ENTER:
      case Keyboard.SPACE:
        event.preventDefault();
        onCancel();
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel={title}
      className="confirm"
      overlayClassName={`confirm__mask ${maskClassName}`}
      parentSelector={getParent}
      aria-modal
      aria-labelledby="confirm__title"
    >
      <div
        className={`confirm__container ${className}`}
      >
        <div id="confirm__title" className="confirm__title" tabIndex="-1">{title}</div>
        { showCloseButton
          && (
            <div className="confirm__close-button" tabIndex="0" role="button" onClick={onCancel} onKeyDown={onCloseButtonKeyDown} aria-label={`close ${title} dialog`}>
              <Icons.Close
                className="confirm__close-icon"
                role="img"
              />
            </div>
          )}
        { children }
        <div className="confirm__bottom-button-group">
          {showCancelButton && <Button as="secondary" className="confirm__bottom-button confirm__bottom-button--secondary" onClick={onCancel} tabIndex="0">{cancelLabel}</Button>}
          <Button as="primary" className="confirm__bottom-button confirm__bottom-button--primary" onClick={onConfirm} disabled={disabled} tabIndex="0">
            {confirmLabel}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

Confirm.propTypes = {
  children: PropTypes.node.isRequired, // - Anything inside the component
  className: PropTypes.string, // - An additional class, optional
  maskClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool, // - disabled the confirm button
  title: PropTypes.string.isRequired, // - Title of the dialog
  onCancel: PropTypes.func, // - onCancel event handler
  onConfirm: PropTypes.func, // - onConfirm event handler
  showCloseButton: PropTypes.bool,
  confirmLabel: PropTypes.string,
  showCancelButton: PropTypes.bool,
  cancelLabel: PropTypes.string,
};

Confirm.defaultProps = {
  isOpen: false,
  className: '',
  maskClassName: '',
  disabled: false,
  onCancel: () => { /* default empty function */ },
  onConfirm: () => { /* default empty function */ },
  showCloseButton: true,
  confirmLabel: 'Confirm',
  showCancelButton: true,
  cancelLabel: 'Cancel',
};
