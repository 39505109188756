import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@at.govt.nz/buttons';
import * as Icons from '../Icons';
import AddressSearch from '../AddressSearch';
import {
  logEventJourneyPlanAddressSelect,
  logJourneyPlannerEvent,
  JOURNEY_PLAN_TYPE,
  JOURNEY_PLANNER_CATEGORIES,
  JOURNEY_PLANNER_ACTION,
} from '../../utils/GALogger';
import { getJourneyPlannerUrlParams } from '../../utils/UrlGenerator';
import Label from '../ATSearch/Label';

function JourneyPlanner({ jpUrl }) {
  const [fromAddress, setFromAddress] = useState(null);
  const [fromText, setFromText] = useState('');
  const [toAddress, setToAddress] = useState(null);
  const [toText, setToText] = useState('');

  const setAddress = (type, data) => {
    if (!data || data.error) {
      return;
    }

    logEventJourneyPlanAddressSelect(
      data,
      JOURNEY_PLAN_TYPE.DEST,
    );

    if (type === 'from') {
      setFromAddress(data);
      setFromText(data.displayText ?? data.address);
    } else {
      setToAddress(data);
      setToText(data.displayText ?? data.address);
    }
  };

  const setFrom = useCallback((text) => {
    setFromText(text);
    setFromAddress(null);
  }, []);

  const setTo = useCallback((text) => {
    setToText(text);
    setToAddress(null);
  }, []);

  const swapFromTo = () => {
    setFromAddress(toAddress);
    setFromText(toText);
    setToAddress(fromAddress);
    setToText(fromText);
  };

  const gotoJourneyPlannerPage = useCallback(() => {
    const journeyPlannerLink = `${jpUrl}?${getJourneyPlannerUrlParams(fromAddress, toAddress)}`;

    logJourneyPlannerEvent(
      JOURNEY_PLANNER_CATEGORIES.NAVIGATION,
      JOURNEY_PLANNER_ACTION.SHOW_EXT_PROVIDERS,
    );

    window.location.href = journeyPlannerLink;
  }, [jpUrl, fromAddress, toAddress]);

  const validateForm = () => fromAddress && toAddress;

  const _handleOnFromAddressDetermined = useCallback((data) => setAddress('from', data), []);
  const _handleOnToAddressDetermined = useCallback((data) => setAddress('to', data), []);

  return (
    <div className="at-flex-grid journeyplanner">
      <div className="row">
        <div className="col-xs-12 form">
          <div className="form__inputs">
            <Label htmlFor="fromAddress">
              <AddressSearch
                id="fromAddress"
                placeholder="Starting point / Wāhi tīmata"
                ariaLabel="Starting point"
                address={fromAddress}
                value={fromText}
                storageKey="journeyPlan"
                hasFrontSpace
                hasCurrentLocation={!!navigator.geolocation}
                onTextChange={setFrom}
                onAddressDetermined={_handleOnFromAddressDetermined}
              />
            </Label>
            <Label htmlFor="toAddress">
              <AddressSearch
                id="toAddress"
                placeholder="Destination / Haerenga"
                ariaLabel="Destination"
                address={toAddress}
                value={toText}
                storageKey="journeyPlan"
                hasFrontSpace
                hasCurrentLocation={!!navigator.geolocation}
                onTextChange={setTo}
                onAddressDetermined={_handleOnToAddressDetermined}
              />
            </Label>
            <div className="form__circles">
              <div className="form__circles__circle"/>
              <div className="form__circles__circle form__circles__circle--dark"/>
              <div className="form__circles__line"/>
            </div>
          </div>
          <div tabIndex="0" className="form__swapButton" onClick={swapFromTo} onKeyPress={swapFromTo} role="button" aria-label="Swap origin and destination" title="Swap origin and destination">
            <Icons.SwapButton/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 submit-button-wrapper">
          <Button disabled={!validateForm()} onClick={gotoJourneyPlannerPage} backgroundColor="dark" block>
            Plan my journey
          </Button>
        </div>
      </div>
    </div>
  );
}

JourneyPlanner.propTypes = {
  jpUrl: PropTypes.string.isRequired,
};

export default JourneyPlanner;
