import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { KEYBOARD } from '../../utils/Config';

function Tabs(props) {
  const {
    children,
    className,
    activeTab,
    onChange,
  } = props;

  const switchTabByKeyboard = (e) => {
    let currentIndex = 0;

    React.Children.forEach(children, (childTab, index) => {
      if (childTab.props.id === activeTab) {
        currentIndex = index;
      }
    });
    let nextIndex = currentIndex;

    switch (e.keyCode) {
      case KEYBOARD.ARROW_RIGHT:
        nextIndex = (currentIndex + 1) % children.length;
        onChange(children[nextIndex].props.id);
        e.stopPropagation();
        break;
      case KEYBOARD.ARROW_LEFT:
        nextIndex = (currentIndex + children.length - 1) % children.length;
        onChange(children[nextIndex].props.id);
        e.stopPropagation();
        break;
      default:
        break;
    }
  };

  return (
    <div
      role="tablist"
      aria-label="Bus Train Ferry Tabs"
      aria-activedescendant={activeTab}
      className={classNames('tabs', className)}
      tabIndex="-1"
      onKeyDown={switchTabByKeyboard}
    >
      {
        React.Children.map(children, (childTab) => {
          const { id, className: tabClassName } = childTab.props;
          return React.cloneElement(childTab, {
            id,
            isActive: activeTab === id,
            onClick: onChange,
            className: classNames(tabClassName),
          });
        })
      }
    </div>
  );
}

Tabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  activeTab: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

Tabs.defaultProps = {
  className: '',
  onChange: () => { /* default empty function */ },
};

export default Tabs;
