import {
  UPDATE_SELECTION,
  SET_ERROR,
  SET_IS_CURRENT_LOCATION,
} from './AddressSelectionAction';

// initial state
export const initialState = {
  selection: null,
  hasCurrentLocation: false,
  isFetching: false,
  error: null,
};

// reducer
export function reducer(state, action) {
  switch (action.type) {
    case UPDATE_SELECTION:
      return {
        ...state,
        selection: action.selection,
        isFetching: false,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
        selection: null,
        isFetching: false,
      };
    case SET_IS_CURRENT_LOCATION:
      return {
        ...state,
        hasCurrentLocation: action.hasCurrentLocation,
        isFetching: true,
      };
    default:
      return state;
  }
}
