export const AT_WEBSITE_URL = window.location.origin;
export const DEFAULT_JP_URL = `${AT_WEBSITE_URL}/bus-train-ferry/journey-planner/`;
export const DEFAULT_LD_URL = `${AT_WEBSITE_URL}/bus-train-ferry/#!/live-departures`;
export const DEFAULT_TT_URL = `${AT_WEBSITE_URL}/bus-train-ferry#!/timetables`;

export const AT_API_URL = process.env.REACT_APP_AT_API_URL;
export const AT_API_KEY = process.env.REACT_APP_AT_API_KEY;
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
export const GEOCODER_MIN_QUERY_LENGTH = process.env.REACT_APP_GEOCODER_MIN_QUERY_LENGTH || 3;
export const CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL;

export const KEYBOARD = {
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ARROW_UP: 38,
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
  TAB: 9,
};

export const DEBOUNCE_TIME = 500;

// Limit of recent address/routes saved locally
export const MAX_RECENT_ADDRESS_SIZE = 10;
export const MAX_RECENT_ROUTE_SIZE = 10;
