import { useState } from 'react';
import qs from 'query-string';
import NetworkError from './ApiError';
import { AT_API_KEY, AT_API_URL } from '../../utils/Config';

const useFetchTimetableRoutes = () => {
  const [timetableRoutes, setTimetableRoutes] = useState([]);

  const fetchTimetableRoutes = async (query, routeTypes) => {
    const params = { search_string: query, route_types: routeTypes };
    setTimetableRoutes({ results: [], isFetching: true });
    const response = await fetch(`${AT_API_URL}timetable/legacy/routes?${qs.stringify(params)}`, {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': AT_API_KEY,
      },
    });

    if (!response.ok) {
      throw new NetworkError(response.status, `Network error: ${response.status}`);
    }

    const data = await response.json();

    if (data.status !== 'OK') {
      throw new NetworkError(data.status, `Network error: ${response.status}`, data.error);
    }

    setTimetableRoutes({ results: data.response, isFetching: false });
  };

  return [timetableRoutes, fetchTimetableRoutes];
};

export default useFetchTimetableRoutes;
