import {
  UPDATE_FETCHING,
  SET_ERROR,
  SET_QUERY,
  UPDATE_SUGGESTIONS,
  RESET_SEARCH,
} from './RouteSearchAction';

// initial state
export const initialState = {
  isFetching: false,
  routeType: null,
  query: '',
  suggestions: [],
  error: null,
};

// reducer
export function reducer(state, action) {
  switch (action.type) {
    case UPDATE_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
        selection: null,
        query: '',
        isFetching: false,
      };
    case SET_QUERY:
      return {
        ...state,
        query: action.query,
      };
    case UPDATE_SUGGESTIONS:
      return {
        ...state,
        suggestions: action.suggestions,
      };
    case RESET_SEARCH:
      return initialState;
    default:
      return state;
  }
}
