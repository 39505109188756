import React from 'react';
import PropTypes from 'prop-types';

function Label({
  htmlFor,
  className,
  text,
  children,
}) {
  return (
    <label htmlFor={htmlFor} className={`address-input-label ${className}`}>
      <span className="label">{text}</span>
      { children }
    </label>
  );
}

Label.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  className: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Label.defaultProps = {
  className: '',
  text: '',
};

export default Label;
