import React from 'react';
import PropTypes from 'prop-types';

export default function SuggestionItem({ children, showOutline }) {
  const classNames = ['suggestion-item'];
  if (showOutline) {
    classNames.push('suggestion-item--highlighted');
  }

  return (
    <div className="suggestion-wrapper">
      <div className={classNames.join(' ')}>
        { children }
      </div>
    </div>
  );
}

SuggestionItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  showOutline: PropTypes.bool.isRequired,
};
