import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import { titleCase } from './Humanizers';

const noRoutesFoundMsg = 'There are no routes found for';
const routeIsNotFound = (route) => isString(route) && route.indexOf(noRoutesFoundMsg) >= 0;

const getRouteLabel = (route) => {
  let shortName = route.route_short_name;
  const lastStopHeadsign = titleCase(route.last_stop_headsign);

  if (!isEmpty(route.display_name)) {
    shortName = `${route.route_short_name} ${route.display_name}`;
  }

  return routeIsNotFound(route) ? route : `${shortName} - to ${lastStopHeadsign}`;
};

const getSuggestionValue = (query, suggestion) => {
  if (routeIsNotFound(suggestion)) {
    return query;
  }
  return getRouteLabel(suggestion);
};

export {
  getRouteLabel,
  getSuggestionValue,
};
