export const composeSuggestionsLiveRegionMessage = (suggestions) => {
  if (!suggestions || suggestions.length < 1) {
    return '';
  }

  const suggestionsCount = suggestions
    .map((s) => (s.results && s.results.length ? s.results.length : 0))
    .reduce((p, n) => p + n);

  return suggestionsCount > 0
    ? `${suggestionsCount} suggestions displayed. To navigate use up and down arrow keys.`
    : '';
};
