// actions
export const UPDATE_FETCHING = 'UPDATE_FETCHING';
export const SET_ERROR = 'SET_ERROR';
export const SET_QUERY = 'SET_QUERY';
export const UPDATE_SUGGESTIONS = 'UPDATE_SUGGESTIONS';

export const setIsFetching = (isFetching) => ({
  type: UPDATE_FETCHING,
  isFetching,
});

export const setError = (error) => ({
  type: SET_ERROR,
  error,
});

export const setQuery = (query) => ({
  type: SET_QUERY,
  query,
});

export const resetSuggestions = () => ({
  type: UPDATE_SUGGESTIONS,
  suggestions: [],
});

export const updateSuggestions = (suggestions) => ({
  type: UPDATE_SUGGESTIONS,
  suggestions,
});
