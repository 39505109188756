import isEmpty from 'lodash/isEmpty';
import split from 'lodash/split';
import ADDRESS_CATEGORIES, { POI_CATEGORY_ICON, UNTYPED_CATEGORY_ICON } from '../constants/addressCategories';

const TOP_SUGGESTIONS = {
  CURRENT_LOCATION: { address: 'Current location / Wāhi ināianei', category: ADDRESS_CATEGORIES.GPS },
  FIND_ON_MAP: { address: 'Find on map / Kimikimi wāhi', category: ADDRESS_CATEGORIES.MAPSTOP },
};

const TITLE_RECENT_SEARCH = 'Recent searches / Rapunga tata';

export function getAddressLabel(address) {
  return address.address;
}

export function getAddressName(address) {
  return address.name;
}
export function getDisplayText(address) {
  return address.displayText ?? address.address;
}

/**
 * Add the saved addresses at the beginning of the address search results
 *
 * @param {*} localResults List of recent addresses matching the query
 * @param {*} apiResults List of addresses from geocoder api
 */
export function combineSearchResults(localResults, apiResults) {
  // If no local results then return the api results
  if (!localResults || !localResults.length) {
    return apiResults || [];
  }

  // If no search results then return the list of saved addresses
  if (!apiResults || !apiResults.length) {
    return localResults;
  }

  // Removing the selected saved addresses from the search results
  const displayedSavedAddress = localResults.map((savedAddress) => savedAddress.address);
  const filteredSearchResults = apiResults.reduce((result, searchResult) => {
    if (displayedSavedAddress.indexOf(searchResult.address) === -1) {
      return result.concat(searchResult);
    }
    return result;
  }, []);

  // Returning the saved addresses first and the search results after
  return localResults.concat(filteredSearchResults);
}

export function isCurrentLocation(address) {
  return address && address.category === ADDRESS_CATEGORIES.GPS;
}

export function isFindOnMap(address) {
  return address && address.category === ADDRESS_CATEGORIES.MAPSTOP;
}

export function getSearchIconName(address) {
  if (address.saved) return 'History';

  switch (address.type) {
    case 'ADDRESS':
      return 'Pin';
    case 'GTFS':
      return 'PublicTransport';
    case 'POI':
      return POI_CATEGORY_ICON[address.category] ?? 'Poi';
    default:
      return UNTYPED_CATEGORY_ICON[address.category] ?? null;
  }
}

export function createSelectionsFromAddresses(results, query, configs) {
  // return pre set search and historical search if no user input
  if (typeof query !== 'string' || isEmpty(query)) {
    const topSuggestions = {
      results: [],
    };

    if (configs && configs.hasCurrentLocation) {
      topSuggestions.results.push(TOP_SUGGESTIONS.CURRENT_LOCATION);
    }
    if (configs && configs.hasFindOnMap) {
      topSuggestions.results.push(TOP_SUGGESTIONS.FIND_ON_MAP);
    }

    if (isEmpty(results)) {
      return [topSuggestions];
    }
    return [topSuggestions, { results, title: TITLE_RECENT_SEARCH }];
  }

  return [{ results }];
}

// GEO address related functions
export function getCurrentPosition() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, { maximumAge: 30000 });
  });
}

// compose address object with params
export function initialiseAddress(address, coordinates) {
  const [lat, lng] = coordinates.split(',');

  return {
    address,
    lat,
    lng,
    category: '',
  };
}

const fixApiAddress = (address) => (address ? split(address, '\n')[0] : '');
export const processLocation = ({
  category, address, lat, lng,
}) => ({
  category, lat, lng, address: fixApiAddress(address),
});
