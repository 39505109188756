import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Search from '@at.govt.nz/search';
import isEmpty from 'lodash/isEmpty';
import { getSuggestionValue, getRouteLabel } from '../../utils/RouteSearchHelper';
import { composeSuggestionsLiveRegionMessage } from '../../utils/SearchHelper';
import useRouteSearch from '../../hooks/RouteSearchHook';
import SectionTitle from '../ATSearch/SectionTitle';
import SuggestionItem from '../ATSearch/SuggestionItem';

const extractSuggestionResults = ({ results }) => results;
const renderSectionTitle = ({ title: sectionTitle }) => (
  <SectionTitle title={sectionTitle} />
);

function RouteSearch(props) {
  const {
    storageKey,
    routeType,
    onRouteDetermined,
    onTextChange,
    id,
    value,
    title,
    placeholder,
    className,
  } = props;

  const {
    query,
    fetching,
    suggestions,
    fetchSuggestions,
    clearSuggestions,
    saveRouteToLocal,
    resetSearch,
  } = useRouteSearch(storageKey, routeType);

  useEffect(() => {
    resetSearch();
  }, [routeType]);

  const _handleFetchSuggestions = useCallback(
    ({ value: suggestionsValue }) => fetchSuggestions(suggestionsValue),
    [fetchSuggestions],
  );

  const _handleGetSuggestionValue = useCallback((suggestion) => getSuggestionValue(query, suggestion), [query]);

  const _onChange = useCallback((event, { newValue }) => onTextChange(newValue), [onTextChange]);
  const _onCleared = useCallback(() => onTextChange(''), [onTextChange]);

  const _onSelectRoute = useCallback((event, { suggestion }) => {
    saveRouteToLocal(suggestion);
    onRouteDetermined(suggestion);
  }, [onRouteDetermined, saveRouteToLocal]);

  const renderSuggestion = (addr, { isHighlighted }) => (
    <SuggestionItem showOutline={isHighlighted && value === getRouteLabel(addr)}>
      <span>{getRouteLabel(addr)}</span>
    </SuggestionItem>
  );

  return (
    <Search
      ariaLabel={`${title} search input field. Enter or select a route or destination.`}
      className={className}
      fetching={fetching}
      getSectionSuggestions={extractSuggestionResults}
      getSuggestionValue={_handleGetSuggestionValue}
      id={id}
      multiSection
      name={id}
      onChange={_onChange}
      onCleared={_onCleared}
      onSuggestionsClearRequested={clearSuggestions}
      onSuggestionSelected={_onSelectRoute}
      onSuggestionsFetchRequested={_handleFetchSuggestions}
      placeholder={placeholder}
      renderSectionTitle={renderSectionTitle}
      renderSuggestion={renderSuggestion}
      suggestions={isEmpty(query) ? [{ results: suggestions, title: 'Recent searches / Rapunga tata' }] : suggestions}
      suggestionsLiveRegionMessage={composeSuggestionsLiveRegionMessage(suggestions)}
      value={value}
    />
  );
}

RouteSearch.propTypes = {
  id: PropTypes.string.isRequired,
  storageKey: PropTypes.string.isRequired,
  routeType: PropTypes.string.isRequired,
  value: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onRouteDetermined: PropTypes.func,
  onTextChange: PropTypes.func,
};

RouteSearch.defaultProps = {
  value: '',
  title: 'Search criteria',
  placeholder: 'Enter route',
  className: '',
  onRouteDetermined: () => { /* default empty function */ },
  onTextChange: () => { /* default empty function */ },
};

export default RouteSearch;
