const ADDRESS_CATEGORIES = {
  ADDRESS: 'ADDRESS',
  BUSSTOP: 'BUSSTOP',
  GPS: 'GPS',
  MAPSTOP: 'MAPSTOP',
  POI: 'POI',
  SAVED: 'SAVED',
};

export const POI_CATEGORY_ICON = {
  AIRPORT: 'PoiAirplane',
  BANK: 'PoiBank',
  BAR: 'PoiBar',
  BEACH: 'PoiBeach',
  BRIDGE: 'PoiBridge',
  CAFE: 'PoiCafe',
  EDUCATION: 'PoiEducation',
  ENTERTAINMENT: 'PoiEntertainment',
  GROCERIES: 'PoiGroceries',
  HOTEL: 'PoiHotel',
  LIBRARY: 'PoiLibrary',
  MARINA: 'PoiMarina',
  MEDICAL: 'PoiMedical',
  OUTDOORS: 'PoiOutdoors',
  OTHER: 'Poi',
  PARKING: 'PoiParking',
  PETROL: 'PoiPetrol',
  POOL: 'PoiPool',
  PUBLIC_TRANSPORT: 'PublicTransport',
  RESTAURANT: 'PoiRestaurant',
  RETAIL: 'PoiRetail',
  SPORTS: 'PoiSports',
  GOLF: 'PoiSportsGolf',
  TENNIS: 'PoiSportsTennis',
  STADIUM: 'PoiStadium',
};

export const UNTYPED_CATEGORY_ICON = {
  [ADDRESS_CATEGORIES.ADDRESS]: 'Pin',
  [ADDRESS_CATEGORIES.BUSSTOP]: 'PublicTransport',
  [ADDRESS_CATEGORIES.GPS]: 'CurrentLocation',
  [ADDRESS_CATEGORIES.MAPSTOP]: 'Pin',
  [ADDRESS_CATEGORIES.POI]: 'PromotedDestination',
  [ADDRESS_CATEGORIES.SAVED]: 'History',
};

export default ADDRESS_CATEGORIES;
