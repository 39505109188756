// ATW-7285 Reinstate below linting rule once timetable recent search is reinstated
/* eslint no-unused-vars: 0 */

import { useState } from 'react';
import useLocalStorage from '@illinois/react-use-local-storage';
import escapeRegExp from 'lodash/escapeRegExp';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import { getRouteLabel } from '../../utils/RouteSearchHelper';
import { MAX_RECENT_ROUTE_SIZE } from '../../utils/Config';

function filterRoutesByQuery(routes, query, routeType) {
  const lowerCaseQuery = query.toLowerCase();
  // the query must match from start of a word only, e.g. when querying 'market', 'newmarket' should NOT match
  const matchFunc = (suggestion) => suggestion && suggestion.route_type === routeType && new RegExp(`\\b${escapeRegExp(lowerCaseQuery)}`, 'gi').test(getRouteLabel(suggestion));

  if (routes && routes.length > 0) {
    return filter(routes, matchFunc).slice(0, 3);
  }
  return [];
}

const useLocalRoute = (routeCategory) => {
  const [routes, setRoutes] = useLocalStorage(routeCategory, []);
  const [filteredRoutes, setFilteredRoutes] = useState(routes);

  const addRoute = (route) => {
    const index = findIndex(routes, route);
    if (index !== -1) {
      routes.splice(index, 1);
    }
    // add the route to the recent list when it is not included currently.
    const length = routes.unshift(route);
    if (length > MAX_RECENT_ROUTE_SIZE) {
      routes.pop();
    }

    /* ATW-7285 Disabling recent search temporarily. Once issues are resolved it will be reinstated.
    setRoutes(routes);
    */
  };

  const filterRoutes = (query, routeType) => setFilteredRoutes(filterRoutesByQuery(routes, query, routeType));

  // This is a temporary function with a one time use to clear previous
  // recent searches cache that uses outdate structure base on the previous GTFS data
  // Will be a dead code overtime
  const removeRecentSearches = () => {
    window.localStorage.removeItem(routeCategory);
  };

  return {
    routes,
    filteredRoutes,
    addRoute,
    filterRoutes,
    removeRecentSearches,
  };
};

export default useLocalRoute;
