import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Search from '@at.govt.nz/search';
import Confirm from '../Confirm/Confirm';
import { getAddressLabel, getAddressName, getDisplayText } from '../../utils/AddressSearchHelper';
import { composeSuggestionsLiveRegionMessage } from '../../utils/SearchHelper';
import SuggestionItem from '../ATSearch/SuggestionItem';
import AddressIcon from './AddressIcon';
import SectionTitle from '../ATSearch/SectionTitle';
import useAddressSearch from '../../hooks/AddressSearchHook';

export default function AddressSearch(props) {
  const {
    storageKey,
    onAddressDetermined,
    onTextChange,
    onAddressCleared,
    id,
    placeholder,
    hasFrontSpace,
    hasCurrentLocation,
    hasFindOnMap,
    value,
    ariaLabel,
  } = props;

  const {
    selection,
    error,
    suggestions,
    fetching,
    selectAddress,
    fetchSuggestions,
    clearSuggestions,
    clearError,
  } = useAddressSearch(storageKey, hasCurrentLocation, hasFindOnMap);

  const onAddressSuggestionSelected = (event, { suggestion }) => {
    selectAddress(suggestion);
  };

  useEffect(() => {
    clearSuggestions();
    onAddressDetermined(selection);
  }, [selection]);

  const onClearButtonClick = () => {
    onTextChange('');
    onAddressCleared();
  };

  const onAddressSuggestionsFetchRequested = ({ value: query }) => fetchSuggestions(query);

  const renderSuggestionItem = (addr, { isHighlighted }) => (
    <SuggestionItem showOutline={isHighlighted && value === getDisplayText(addr)}>
      <AddressIcon addr={addr} />
      <span className="suggestion-item__wrapper">
        <span className="suggestion-item__title">{getAddressName(addr)}</span>
        <span className="suggestion-item__content">{getAddressLabel(addr)}</span>
      </span>
    </SuggestionItem>
  );

  return (
    <>
      <Confirm isOpen={!!error} showCloseButton={false} showCancelButton={false} confirmLabel="OK" title="Location services not available" onConfirm={clearError}>
        <p className="location-permission-rationale-content">
          Unfortunately we are unable to find your location due to location services being disabled. Please enable location services to use this feature.
        </p>
      </Confirm>
      <Search
        id={id}
        leftSpace={hasFrontSpace}
        multiSection
        name={id}
        value={value}
        onChange={(event, { newValue }) => onTextChange(newValue)}
        onCleared={onClearButtonClick}
        fetching={fetching}
        ariaLabel={ariaLabel}
        renderSearchButton
        placeholder={placeholder}
        suggestions={suggestions}
        suggestionsLiveRegionMessage={composeSuggestionsLiveRegionMessage(suggestions)}
        onSuggestionsFetchRequested={onAddressSuggestionsFetchRequested}
        onSuggestionsClearRequested={clearSuggestions}
        onSuggestionSelected={onAddressSuggestionSelected}
        getSuggestionValue={getDisplayText}
        getSectionSuggestions={(section) => section.results}
        renderSuggestion={renderSuggestionItem}
        renderSectionTitle={({ title }) => (<SectionTitle title={title} />)}
      />
    </>
  );
}

AddressSearch.propTypes = {
  placeholder: PropTypes.string,
  hasFrontSpace: PropTypes.bool,
  hasCurrentLocation: PropTypes.bool,
  hasFindOnMap: PropTypes.bool,
  storageKey: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onAddressDetermined: PropTypes.func,
  onTextChange: PropTypes.func,
  onAddressCleared: PropTypes.func,
  value: PropTypes.string,
  ariaLabel: PropTypes.string,
};

AddressSearch.defaultProps = {
  placeholder: '',
  hasFrontSpace: false,
  hasCurrentLocation: false,
  hasFindOnMap: false,
  onAddressDetermined: () => { /* default empty function */ },
  onTextChange: () => { /* default empty function */ },
  onAddressCleared: () => { /* default empty function */ },
  value: '',
  ariaLabel: '',
};
