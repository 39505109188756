import JoinStrings from './JoinStrings';

const GenerateAddress = (name, type, stopCode, address) => {
  let returnAddress;
  switch (type) {
    case 'GTFS':
      returnAddress = `Stop ${stopCode ?? ''}`;
      break;
    case 'POI':
      returnAddress = JoinStrings([address?.street, address?.locality, address?.place, address?.postcode]);
      break;
    case 'ADDRESS':
      returnAddress = JoinStrings([address?.locality, address?.place, address?.postcode]);
      break;
    default:
      returnAddress = '';
  }

  return returnAddress !== '' ? returnAddress : 'New Zealand';
};

export default GenerateAddress;
