import { useState } from 'react';
import useLocalStorage from '@illinois/react-use-local-storage';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import { MAX_RECENT_ADDRESS_SIZE } from '../../utils/Config';

function getTopResults(addresses) {
  if (addresses && addresses.length) {
    return addresses.slice(0, 3).map((address) => ({ saved: true, ...address }));
  }
  return [];
}
function getMatchedResults(addresses, query) {
  const lowerCaseQuery = query.toLowerCase();

  if (addresses && addresses.length) {
    const matchedAddresses = filter(
      addresses,
      (address) => (address.address && address.address.toLowerCase().indexOf(lowerCaseQuery) !== -1)
      || (address.name && address.name.toLowerCase().indexOf(lowerCaseQuery) !== -1)
      ,
    );
    return matchedAddresses.map((address) => ({ saved: true, ...address }));
  }
  return [];
}

const useLocalAddress = (addressCategory) => {
  const [addresses, setAddresses] = useLocalStorage(addressCategory, []);
  const [filteredAddresses, setFilteredAddresses] = useState(addresses);

  const addAddress = (address) => {
    const index = findIndex(addresses, { address: address.address });
    if (index !== -1) {
      addresses.splice(index, 1);
    }
    // add the address to the recent list when it is not included currently.
    const length = addresses.unshift(address);
    if (length > MAX_RECENT_ADDRESS_SIZE) {
      addresses.pop();
    }

    setAddresses(addresses);
  };

  const filterAddresses = (query) => setFilteredAddresses(getTopResults(getMatchedResults(addresses, query)));

  return {
    addresses,
    filteredAddresses,
    addAddress,
    filterAddresses,
  };
};

export default useLocalAddress;
